import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`14th April 2020`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">{`Added `}<inlineCode parentName="li">{`RetryStrategy.onException()`}</inlineCode>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2652"
        }}>{`#2652`}</a></li>
      <li parentName="ul">{`Armeria clients can now send an `}<inlineCode parentName="li">{`OPTIONS * HTTP/1`}</inlineCode>{` request as an alternative to an HTTP/2 PING frame. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2636"
        }}>{`#2636`}</a>
        <ul parentName="li">
          <li parentName="ul">{`This feature is disabled by default and you can enable it by:`}
            <ul parentName="li">
              <li parentName="ul">{`specifying `}<inlineCode parentName="li">{`com.linecorp.armeria.defaultPingIntervalMillis`}</inlineCode>{` system property`}</li>
              <li parentName="ul">{`or calling `}<inlineCode parentName="li">{`ClientFactoryBuilder.pingIntervalMillis()`}</inlineCode>{`.`}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`DocService`}</inlineCode>{` debug form now prettifies any JSON responses. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2635"
        }}>{`#2635`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`grpc-timeout`}</inlineCode>{` gRPC client header is generated correctly now. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2643"
        }}>{`#2643`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`QueryParams.toQueryString()`}</inlineCode>{` does not raise an exception anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2644"
        }}>{`#2644`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`HealthCheckedEndpointGroup`}</inlineCode>{` does not raise a`}<inlineCode parentName="li">{`ConcurrentModificationException`}</inlineCode>{` anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2647"
        }}>{`#2647`}</a></li>
      <li parentName="ul">{`Thrift TEXT protocol does not fail to handle a recursive union struct anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2654"
        }}>{`#2654`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`MoreNamingConventions.prometheus()`}</inlineCode>{` now appends the `}<inlineCode parentName="li">{`seconds`}</inlineCode>{` suffix for `}<inlineCode parentName="li">{`LongTaskTimer`}</inlineCode>{` correctly, when using legacy meter names. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2646"
        }}>{`#2646`}</a></li>
      <li parentName="ul">{`Fixed a bug where `}<inlineCode parentName="li">{`DocService`}</inlineCode>{` sends a request with a wrong HTTP method if a service specification contains duplicate methods. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2657"
        }}>{`#2657`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`com.linecorp.armeria.defaultHttp2PingTimeoutMillis`}</inlineCode>{` flag has been replaced with `}<inlineCode parentName="li">{`com.linecorp.armeria.defaultPintIntervalMillis`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`com.linecorp.armeria.defaultUseHttp2PingWhenNoActiveStreams`}</inlineCode>{` flag has been removed without a replacement, because:`}
        <ul parentName="li">
          <li parentName="ul">{`HTTP/2 can send PING frame regardless of whether there is an active stream or not.`}</li>
          <li parentName="ul">{`HTTP/1 can only send `}<inlineCode parentName="li">{`OPTIONS * HTTP/1.1`}</inlineCode>{` when there is no active stream.`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`ClientFactoryBuilder.http2PingTimeoutMillis()`}</inlineCode>{` has been replaced with `}<inlineCode parentName="li">{`ClientFactoryBuilder.pingIntervalMillis()`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ServerBuilder.http2PingTimeoutMillis()`}</inlineCode>{` has been replaced with `}<inlineCode parentName="li">{`ServerBuilder.pingIntervalMillis()`}</inlineCode>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Brave 5.10.2 → 5.11.2`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['AmosDoan', 'anuraaga', 'codefromthecrypt', 'eugene70', 'gary-lo', 'hueneburg', 'huydx', 'ikhoon', 'mauhiz', 'minwoox', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      